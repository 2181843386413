<template>
  <div>
    <div class="row page-title align-items-center">
      <div v-if="plan && plan.id && !isSalePlan" class="col-sm-12">
        <div class="d-flex align-items-center">
          <a
            :href="encodeURI(`${location.origin}/order?plan=${plan.id}`)"
            target="_blank"
          >
            <strong>{{
              encodeURI(`${location.origin}/order?plan=${plan.id}`)
            }}</strong>
          </a>
          <span v-b-tooltip.hover title="Copy this link" class="copy-icon pl-2" @click="copyLink">
            <feather type="copy" />
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div v-if="!loadingPlan">
              <ValidationObserver
                v-if="plan"
                v-slot="{ handleSubmit, invalid, touched }"
                ref="updateForm"
              >
                <form
                  class="authentication-form"
                  @submit.prevent="handleSubmit(updatePlan)"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <TextInput
                        v-model="plan.name"
                        name="name"
                        rules="required"
                        label="Plan Name"
                      />
                    </div>
                    <div class="col-md-6">
                      <TextInput
                        v-model="plan.desc"
                        name="name"
                        rules="required"
                        label="Plan Desc."
                      />
                    </div>
                    <div class="col-md-6">
                      <TextInput
                        v-model="plan.amount"
                        name="price"
                        rules="required"
                        label="Price ($)"
                        :readonly="true"
                      />
                    </div>
                    <div class="col-md-6">
                      <TextInput
                          v-model="plan.trial_period_days"
                          rules="numeric"
                          name="trial_period_days"
                          label="Free Trial Days"
                          type="number"
                      />
                    </div>
                    <div v-if="isSalePlan" class="col-md-6">
                      <TextInput
                        v-model="plan.video"
                        name="video"
                        label="Video"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <label class="form-control-label">Product Type</label>
                      <b-form-select v-model="plan.db_plan.product_type" disabled :options="[{ value: 'ReplyEngine', text: 'ReplyEngine' }, { value: 'TapEngine', text: 'TapEngine' }, { value: 'SocialReview', text: 'SocialReview' },{ value: 'ReviewSurge', text: 'ReviewSurge' },{ value: 'LeadMachine', text: 'LeadMachine' }, { value: 'SocialFusion', text: 'SocialFusion' }, { value: 'InstaStream', text: 'InstaStream' }, { value: 'RepuAgency', text: 'RepuAgency' }, { value: 'ZenSocial', text: 'ZenSocial' }]" @change="updatePlanProducts"  />
                    </div>
                    <div class="col">
                      <TextInput
                          v-model="plan.db_plan.max_customers"
                          name="max-customers"
                          label="Max. Customers"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label class="form-control-label mb-3">Plan Products</label>
                      <b-form-checkbox-group v-model="plan.db_plan.planProducts">
                        <div v-for="planProduct in ['ReplyEngine', 'TapEngine', 'SocialReview', 'ReviewSurge','LeadMachine', 'SocialFusion', 'InstaStream', 'RepuAgency', 'ZenSocial']" :key="planProduct" class="mb-3">
                          <b-form-checkbox
                              :value="planProduct"
                              :disabled="plan.db_plan.product_type === planProduct"
                          >
                            {{ planProduct }}
                          </b-form-checkbox>
                        </div>

                      </b-form-checkbox-group>
                    </div>
                  </div>
                  <div class="row d-none">
                    <div class="col-12 col-sm-6">
                      <label class="form-control-label">Upsells</label>
                      <b-form-select v-model="plan.db_plan.upsells" multiple :options="[{ name: 'No Upsell', id: null },...plans]" text-field="name" value-field="id"  />
                    </div>
                  </div>
                  <h5 class="h5">Features</h5>
                  <table class="table table-bordered meta-table">
                    <thead class="thead-light">
                      <tr>
                        <th>Key</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in plan.features" :key="item.key">
                        <td>
                          <strong>{{item.key}}</strong>
                        </td>
                        <td>
                          <TextInput
                            v-if="item.key === 'Number of Businesses'"
                            v-model="item.value"
                            name="value"
                          />
                          <b-form-checkbox
                            v-else
                            v-model="item.value"
                            name="check-button"
                            switch
                            inline
                            class="mt-2"
                          >
                          </b-form-checkbox>
                        </td>
                      </tr>
                      <tr v-if="!isSalePlan">
                        <td>
                          <strong>Visible</strong>
                        </td>
                        <td>
                          <b-form-checkbox
                            v-model="plan.visible"
                            name="check-button"
                            switch
                            inline
                            class="mt-2"
                          >
                          </b-form-checkbox>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Premium Plan</strong>
                        </td>
                        <td>
                          <b-form-checkbox
                              v-model="plan.db_plan.premium_plan"
                              name="check-button"
                              switch
                              inline
                              class="mt-2"
                          >
                          </b-form-checkbox>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <b-form-group>
                    <b-button
                      variant="primary"
                      class="btn-dark-blue"
                      :disabled="loadingUpdate || (invalid && touched)"
                      type="submit"
                    >
                      <b-spinner v-if="loadingUpdate" small />
                      <span v-else>Update</span>
                    </b-button>
                  </b-form-group>
                </form>
              </ValidationObserver>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  data() {
    return {
      location: location,
      loadingPlan: false,
      plan: {
        metadata: [{ key: 'Number of Businesses', value: '' }],
      },
      dbPlan: null,
      loadingUpdate: false,
      newFeature: {
        key: '',
        value: '',
      },
    }
  },

  computed: {
    plans() {
      let plans = this.$store.getters['subscription/allPlans'] || []

      return plans.filter(
        (p) => this.plan && this.plan.id !== p.id
      )
    },

    isSalePlan() {
      if (!this.plan || (this.plan && !this.plan.features)) return false

      let salePlan = this.plan.features.filter((f) => f.key === 'Sales Plan')[0]
      return salePlan && salePlan.value
    },
  },

  mounted() {
    if (!this.$store.getters['subscription/allPlans']) {
      this.$store.dispatch('subscription/getAllPlans')
    }

    this.getPlan()
  },

  methods: {
    updatePlanProducts() {
      // Reset planProducts and only check the new product_type
      this.plan.db_plan.planProducts = [this.plan.db_plan.product_type];
    },
    copyLink() {
      var tempInput = document.createElement("input");
      tempInput.value = `${location.origin}/order?plan=${this.plan.id}`;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);

      Vue.$toast.open({
        message: 'Copied',
        type: 'success',
        duration: 5000,
      })
    },

    updatePlan() {
      this.loadingUpdate = true
      this.$store
        .dispatch('subscription/updatePlan', this.plan)
        .then(() => {
          this.loadingUpdate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingUpdate = false
        })
    },

    getPlan() {
      this.loadingPlan = true

      this.$store
        .dispatch('subscription/findPlan', this.$route.params.id)
        .then((plan) => {
          this.plan = plan
          this.loadingPlan = false
        })
        .catch(() => {
          this.$router.push({ name: 'admin.agencies.edit' })
          this.loadingPlan = false
        })
    },

    addMetadata() {
      this.plan.metadata.push(this.newFeature)
      this.newFeature = { key: '', value: '' }
    },

    removeMetadata(index) {
      this.plan.metadata.splice(index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
  .meta-table {
    td, th {
      vertical-align: middle;
    }
    .form-group {
      margin-bottom: 0!important;
    }
  }
  .copy-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      width: 16px;
      color: #5369f8;
    }
  }
</style>
